<template>
  <div>
    <div
      style="font-size: 20px; font-weight: 600; margin: 20px 0 20px 0"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
    >
      打卡未报价排行榜
      <i class="el-icon-warning" style="color: #409eff; margin: 0 10px"></i>
      <span style="font-size: 14px" :class="active == 0 ? 'active1' : 'active2'"
        >打卡24小时后开始计算</span
      >
    </div>

    <div style="display: flex; justify-content: start; margin: 20px 0 0 5px">
      <el-select
        :clearable="true"
        v-model="city"
        placeholder="选择城市"
        class="littleBox inputStyle"
        @change="choseCity2(city)"
        filterable
      >
        <el-option
          v-for="(item, index) in cities"
          :key="index"
          :label="item.city"
          :value="item.city_id"
        ></el-option>
      </el-select>
      <el-select
        :clearable="true"
        v-if="city != ''"
        v-model="shopper"
        placeholder="选择店长"
        class="littleBox rankStyle inputStyle"
        @change="choseShopkeeper2(shopper)"
        filterable
      >
        <el-option
          v-for="(item, index) in shoppers"
          :key="index"
          :label="item.username"
          :value="item.user_id"
        ></el-option>
      </el-select>
      <el-select
        :clearable="true"
        v-if="city != ''"
        v-model="store"
        placeholder="选择店铺"
        class="littleBox rankStyle inputStyle"
        @change="choseStore2(store)"
        filterable
      >
        <el-option
          v-for="(item, index) in stores"
          :key="index"
          :label="item.store_name"
          :value="item.store_name"
        ></el-option>
      </el-select>
      <div>
        <span style="margin: 0 10px 0 20px; font-size: 14px">下单时间:</span>
        <el-date-picker
          v-model="create_time2"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="datePicker"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-button
        type="primary"
        size="small"
        plain
        @click="getClockList"
        style="margin-left: 20px"
        >筛选</el-button
      >
    </div>
    <avue-crud
      :data="tableData2"
      :option="option2"
      v-model="obj2"
      style="margin-top: 15px"
    >
      <template slot="overtime" slot-scope="scope">
        <div>{{ scope.row.overtime }}小时</div>
      </template>
    </avue-crud>
    <div class="flex_start mt20 flex_items">
      <span class="demonstration mr20">
        总共
        <span style="color: red">{{ count2 }}</span
        >条数据,当前 <span style="color: red">{{ tableData2.length }}</span
        >条
      </span>
      <el-pagination
        @current-change="handleCurrentChange2"
        :current-page.sync="currentPage2"
        :page-size="20"
        :total="count2"
        layout="prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  //   props: {
  //     mobile: Number
  //   },
  data() {
    return {
      active: 0,
      city: "",
      cities: [],
      shopper: "",
      shoppers: [],
      store: "",
      stores: [],
      create_time2: "",
      count2: 0,
      tableData2: [],
      option2: {
        border: true,
        size: "medium",
        menu: false,
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        columnBtn: false, //列显隐按钮
        refreshBtn: false, //刷新按钮
        //   delBtn:false,
        //   editBtn:false,
        column: [
          {
            label: "店长",
            prop: "username",
            width: "150px",
          },
          {
            label: "店铺",
            prop: "store_name",
            width: "100px",
          },
          {
            label: "订单号",
            prop: "order_id",
          },
          {
            label: "工地地址",
            prop: "addres",
          },
          {
            label: "客户姓名",
            prop: "contacts",
            width: "100px",
          },
          {
            label: "客户电话",
            prop: "telephone",
            width: "130px",
          },
          {
            label: "下单时间",
            prop: "created_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
            sortable: true,
          },
          {
            label: "打卡时间",
            prop: "clock_in_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
          },
          {
            label: "超时天数",
            prop: "overtime",
            slot: true,
            sortable: true,
          },
        ],
      },
      obj2: {},
      currentPage2: 1,
      time2: 0,
    };
  },
  created() {
    this.getCity();
    this.getClockList();
  },
  watch: {},
  methods: {
    mouseOver: function () {
      this.active = 1;
    },
    mouseLeave: function () {
      this.active = 0;
    },
    getCity() {
      //获取城市
      this.util.get(this.HOST + "/Common/cheng", {}).then((res) => {
        if (res.code == 200) {
          this.cities = res.data;
          this.cities5 = res.data;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    choseCity2(city) {
      //选择城市-打卡未报价
      this.shopper = "";
      this.store = "";
      this.getShopper(city);
      this.getStores(city);
      this.getClockList();
    },
    getShopper(city) {
      //获取店长
      this.util
        .get(this.HOST + "/Common/user", {
          city_id: city,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getStores(city) {
      //获取选取城市的店铺
      this.util
        .get(this.HOST + "/Common/store", {
          city_id: city,
        })
        .then((res) => {
          //console.log(res, "stores++++");
          if (res.code == 200) {
            this.stores = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getClockList() {
      //打卡未报价排行榜列表
      this.$loading(this.util.opload);
      if (this.create_time2 == null || this.create_time2 == "") {
        this.create_time2 = "";
      }
      let time_start = this.util.datatime(this.create_time2[0]);
      let time_end = this.util.datatime(this.create_time2[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.util
        .get(this.HOST + "/Order/Clock", {
          page: 1,
          limit: 20,
          user_id: this.shopper,
          store_id: this.store,
          city_id: this.city,
          desc: this.time2,
          time_start: time_start,
          time_end: time_end,
        })
        .then((res) => {
          if (res.code == 200) {
            //console.log(res.data.count, "res.data.count!!!!!+++++");
            this.count2 = res.data.count;
            for (let i = 0; i < res.data.list.length; i++) {
              res.data.list[i].clock_in_time = this.util.timestampToTime(
                res.data.list[i].clock_in_time
              );
            }
            this.tableData2 = res.data.list;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    handleCurrentChange2(val) {
      if (this.create_time2 == null || this.create_time2 == "") {
        this.create_time2 = "";
      }
      let time_start = this.util.datatime(this.create_time2[0]);
      let time_end = this.util.datatime(this.create_time2[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.$loading(this.util.opload);
      this.tableData2 = [];
      this.util
        .get(this.HOST + "/Order/Clock", {
          limit: 20,
          user_id: "",
          store_id: "",
          city_id: "",
          page: this.currentPage2,
          desc: this.time2,
          time_start: time_start,
          time_end: time_end,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count2 = res.data.count;
            for (let i = 0; i < res.data.list.length; i++) {
              res.data.list[i].clock_in_time = this.util.timestampToTime(
                res.data.list[i].clock_in_time
              );
              this.tableData2 = res.data.list;
            }
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    choseShopkeeper2(value) {
      //选择店长之后的操作-打卡未报价
      this.getClockList();
    },
    choseStore2(value) {
      //选择店铺之后-打卡未报价
      this.getClockList();
    },
  },
};
</script>
<style scoped>
.content {
  margin: 0 auto;
  background: white;
  padding: 10px 0 0 30px;
  border-radius: 8px;
  height: 100%;
}
.child {
  width: 24%;
  margin-bottom: 10%;
}
.number {
  font-size: 26px;
  /* text-align: center; */
  color: #999;
  margin: 5% 0 0 25%;
}
.title {
  font-size: 16px;
  margin-top: 20px;
  text-align: left;
  color: #666;
  width: 145px;
}
.sort {
  margin-bottom: -20px;
}
.rankStyle {
  margin-left: 20px;
}
.selectedTime {
  color: #409eff;
}
.returnIcon:hover {
  color: cornflowerblue;
}
div /deep/ .avue-crud__menu {
  min-height: 0px !important;
}
.el-icon-warning:hover span {
  display: block;
}
::v-deep .datePicker {
  width: 256px;
  height: 32px !important;
  line-height: 32px !important;
}
::v-deep .littleBox input {
  height: 32px !important;
  line-height: 32px !important;
  width: 160px;
}
.active1 {
  display: none;
}
.active2 {
  display: inline;
}
</style>
